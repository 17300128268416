@use 'src/styles/variables';

.dropdown {
  background: variables.$color-white;
  border: 1px solid variables.$color-blue-500;
  border-radius: variables.$border-radius;
  width: 400px;
  position: absolute;
  top: calc(100% + #{variables.$margin-extra-small});
  z-index: 2;
}

.searchInputWrapper {
  padding: variables.$margin-small variables.$margin-small 0;
}
