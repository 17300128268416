@use 'src/styles/variables';

.container {
  font-weight: 400;
  row-gap: 2px;
  display: flex;
  flex-direction: column;
}

.availableFilters {
  display: flex;
  align-items: center;
}

.icon {
  align-items: center;
  display: flex;
  height: 32px;
  width: 24px;

  svg {
    height: 16px;
  }
}

.selectedFilters {
  display: flex;
  flex-wrap: wrap;
  gap: variables.$margin-extra-small;
}
