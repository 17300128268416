@use 'src/styles/variables';

.label {
  padding-right: variables.$margin-small;
}

.label:not(:first-child) {
  border-left: 1px solid variables.$color-grey-300;
  padding-left: variables.$margin-small;
}
