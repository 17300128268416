@use 'src/styles/variables';
@use 'src/styles/mixins';

@mixin colored-button {
  background: variables.$color-grey-200;
  border-color: transparent;
}

@mixin disabled-button {
  background-color: variables.$color-grey-200;
  color: variables.$color-grey-600;
  cursor: default;
}

.button {
  @include mixins.button-reset;
  align-items: center;
  background-color: variables.$color-white;
  border: 1px variables.$color-grey-200 solid;
  border-radius: 4px;
  color: variables.$color-black;
  display: flex;
  font-size: variables.$font-size-small;
  font-weight: normal;
  justify-content: center;
  line-height: 16px;
  min-width: auto;
  position: relative;
  white-space: nowrap;

  &.small {
    padding: 4px 8px;
  }

  &.medium {
    padding: 8px 12px;
  }

  &.large {
    padding: 12px 16px;
  }

  &:hover,
  &:active {
    background-color: variables.$color-grey-200;
  }

  &:disabled {
    @include disabled-button;
  }
}

.spinIcon {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.submitButton {
  height: 40px;
  padding: 12px 16px;
}

.successThemeButton {
  @include colored-button;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: variables.$color-green-200;
    color: variables.$color-green-700;
  }
}

.processThemeButton {
  @include colored-button;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: variables.$color-blue-100;
    color: variables.$color-blue-600;
  }
}

.importantThemeButton {
  @include colored-button;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: variables.$color-red-200;
    color: variables.$color-red-500;
  }
}

.warningThemeButton {
  @include colored-button;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background: variables.$color-orange-50;
    color: variables.$color-orange-500;
  }
}

.primaryThemeButton {
  background-color: variables.$color-blue-500;
  border-color: transparent;
  color: variables.$color-white;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background-color: variables.$color-blue-600;
  }

  &:disabled {
    @include disabled-button;
  }
}

.cancelThemeButton,
.submitThemeButton {
  border: 0;
  width: 100%;
  height: 100%;
}

.cancelThemeButton {
  color: variables.$color-red-600;

  &:disabled {
    background: variables.$color-white;
    color: variables.$color-red-600;
    opacity: 40%;
  }

  &:active:not(:disabled) {
    color: variables.$color-red-600;
  }

  &:hover:not(:disabled) {
    background: variables.$color-red-200;
    color: variables.$color-red-600;
  }
}

.submitThemeButton {
  color: variables.$color-blue-500;

  &:disabled {
    background: variables.$color-white;
    color: variables.$color-blue-500;
    opacity: 40%;
  }

  &:active:not(:disabled) {
    color: variables.$color-blue-500;
  }

  &:hover:not(:disabled) {
    background: variables.$color-blue-100;
    color: variables.$color-blue-500;
  }
}

.link {
  @include mixins.button-reset;
  align-items: center;
  color: variables.$color-blue-500;
  display: inline-flex;
  font-size: variables.$font-size-small;
  font-weight: normal;
  gap: 4px;
  line-height: 16px;

  &:hover,
  &:active {
    color: variables.$color-blue-600;
  }

  &:disabled {
    color: variables.$color-grey-500;
    cursor: default;
  }
}

.importantThemeLink {
  color: variables.$color-red-500;

  &:hover,
  &:active {
    color: variables.$color-red-600;
  }
}

.greyThemeLink {
  color: variables.$color-grey-600;

  &:hover,
  &:active {
    color: variables.$color-blue-600;
  }
}

.underlined {
  border-bottom: 1px dotted currentColor;
  bottom: -1px;
  position: relative;
}

.hidden {
  visibility: hidden;
}

.contentWrapper {
  align-items: center;
  column-gap: 2px;
  display: flex;
}
