@use 'src/styles/variables';
@use 'src/styles/mixins';

.resetButton {
  @include mixins.button-reset;

  border-radius: variables.$border-radius;
  color: variables.$color-red-600;
  cursor: pointer;
  font-size: variables.$font-size-small;
  line-height: 12px;
  padding: 10px variables.$margin-small;

  &:hover:not(:disabled) {
    background: variables.$color-red-200;
    color: variables.$color-red-600;
  }

  &:active:not(:disabled) {
    background: transparent;
    color: variables.$color-red-600;
  }

  &:disabled {
    cursor: default;
    opacity: 40%;
  }
}
