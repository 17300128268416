@use 'src/styles/variables';
@use 'src/styles/mixins';

.container {
  background: variables.$color-white;
  border: 1px solid variables.$color-blue-500;
  border-radius: variables.$border-radius;
  min-width: 240px;
  max-width: 320px;
  position: absolute;
  top: calc(100% + #{variables.$margin-extra-small});
  z-index: 2;
}

.footerWrapper {
  border-top: 1px solid variables.$color-grey-300;
}
