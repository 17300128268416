@use 'src/styles/variables';
@use 'src/styles/mixins';

.container {
  @include mixins.button-reset;

  align-items: center;
  background-color: variables.$color-white;
  border: 1px solid variables.$color-grey-200;
  border-radius: variables.$border-radius;
  display: flex;
  font-size: 12px;
  padding: 4px 4px 4px 12px;

  &:hover:not(.disabled) {
    color: variables.$color-blue-grey-600;
  }

  &:active:not(.disabled) {
    background-color: variables.$color-white;
    color: variables.$color-black;
  }

  &.disabled {
    cursor: default;
    opacity: 40%;
  }

  &.opened {
    border-color: variables.$color-blue-500;
    background-color: variables.$color-white;
    color: variables.$color-black;
  }

  &.opened:hover {
    border-color: variables.$color-blue-500;
    background-color: variables.$color-white;
    color: variables.$color-blue-grey-600;
  }

  &.opened:active:not(.disabled) {
    background-color: variables.$color-white;
    color: variables.$color-black;
  }
}

.activeFilters {
  align-items: center;
  column-gap: variables.$margin-small;
  display: flex;
  margin-right: variables.$margin-extra-small;
}

.activeFilter {
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  white-space: nowrap;

  &:not(:first-child) {
    border-left: 1px solid variables.$color-grey-300;
    padding-left: 8px;
  }

  &.singleItem {
    max-width: 205px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
