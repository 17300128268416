@use 'src/styles/variables';
@use 'src/styles/mixins';

.trigger {
  @include mixins.button-reset;

  border: 1px solid transparent;
  border-radius: variables.$border-radius;
  cursor: pointer;
  color: variables.$color-grey-500;
  font-size: variables.$font-size-small;
  line-height: 12px;
  padding: 10px variables.$margin-small;

  &:hover:not(:disabled) {
    background: variables.$color-grey-200;
    color: variables.$color-grey-600;
  }

  &:active:not(:disabled) {
    background: transparent;
    color: variables.$color-grey-500;
  }

  &:disabled {
    cursor: default;
    opacity: 40%;
  }

  &.opened {
    border-color: variables.$color-blue-500;
    background-color: variables.$color-white;
    color: variables.$color-black;
  }

  &.opened:hover {
    border-color: variables.$color-blue-500;
    background-color: variables.$color-white;
    color: variables.$color-blue-grey-600;
  }

  &.opened:active:not(:disabled) {
    background-color: variables.$color-white;
    color: variables.$color-black;
  }
}
