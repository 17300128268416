@use 'src/styles/variables';
@use 'src/styles/mixins';

.list {
  border-bottom: 1px solid variables.$color-grey-300;
  padding: variables.$margin-small;
  max-height: 280px;
  overflow-y: auto;
}

.dropdownLoader {
  border: 0;
  padding: 10px;
}

.option {
  @include mixins.dropdown-option;
  line-height: 16px;
  gap: variables.$margin-small;
  border-radius: variables.$border-radius-small;
}

.selectedOption {
  background-color: variables.$color-blue-500;
  color: variables.$color-white;

  .labelRight {
    color: variables.$color-white;
  }
}

.label {
  flex: 0 1 auto;
  min-width: 0;
  max-width: calc(100% - 110px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.labelRight {
  color: variables.$color-grey-600;
  flex: 1 1 110px;
  min-width: 110px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}

.searchWarningText {
  color: variables.$color-grey-600;
  font-size: variables.$font-size-small;
  line-height: 16px;
  padding: variables.$margin-small;
}
