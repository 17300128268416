@use 'src/styles/variables';
@use 'src/styles/mixins';

.list {
  border-bottom: 1px solid variables.$color-grey-300;
  padding: variables.$margin-small;
  max-height: 280px;
  overflow-y: auto;
}

.checkbox {
  padding: variables.$margin-small;
  width: 100%;
}

.additionalData {
  font-size: variables.$font-size-small;
  color: variables.$color-grey-600;
  padding-left: variables.$margin-extra-large;
}

.dropdownLoader {
  border: 0;
  padding: 10px;
}

.searchWarningText {
  color: variables.$color-grey-600;
  font-size: variables.$font-size-small;
  line-height: 16px;
  padding: variables.$margin-small;
}

.noDataMessage {
  text-align: center;
  font-size: variables.$font-size-common;
}

.hiddenListPadding {
  padding-top: 0;
  padding-bottom: 0;
}

.withAdditionalData {
  padding-bottom: 0;
}
