@use 'src/styles/variables';

.mainContainer:not(:last-child) {
  margin-bottom: variables.$margin-small;
}

.contentContainer {
  &:not(:last-child) {
    line-height: 16px;
    margin-bottom: variables.$margin-large;
  }

  &.collapsable {
    line-height: 16px;
  }
}

.withBorder {
  border: 1px solid variables.$color-grey-300;
  border-radius: variables.$border-radius;
  padding: variables.$margin-common;

  .contentContainer:not(:last-child) {
    margin-bottom: variables.$margin-small;
  }
}

.title {
  align-items: center;
  column-gap: variables.$margin-large;
  display: flex;
  justify-content: space-between;
  line-height: 20px;

  &.clickable {
    cursor: pointer;
  }
}

.subTitle {
  color: variables.$color-grey-600;
  font-size: variables.$font-size-small;
}

.icon.closed {
  transform: rotate(180deg);
}

.leftTitleContainer {
  align-items: center;
  display: flex;
  gap: variables.$margin-extra-small;
}

.subTitle.closed {
  margin-top: variables.$margin-small;
}
