@use 'src/styles/variables';

.buttons {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: space-around;

  .button {
    border-radius: variables.$border-radius;
  }
}

.buttonContainer {
  border-left: 1px solid transparent;
  height: 100%;
  position: relative;
  width: 100%;

  &::before {
    background-color: variables.$color-grey-300;
    content: '';
    height: 12px;
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
  }
}
