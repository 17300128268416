@use 'src/styles/variables';
@use 'src/styles/mixins';

.headerContent {
  @include mixins.buttons-row;
}

.searchWrapper {
  margin-bottom: 0;
}

.filter {
  margin: variables.$margin-small 0;
}
